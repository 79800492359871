<template>
  <div class="body22">
    <div class="back">
      <div class="logo">
        <img class="large" :src="$tenant.bigLogo" style="height: 50px"/>
      </div>
      <div v-if="showContent" class="content">
        <div style="border: solid 1px #D63B3F;background-color: #FBEBEB;">
          <div style="width: 100%">
            <div style="width: 100%">
            <span
              style="padding-left:20px;font-size: 20px;font-weight: 600;color: #333333">{{$t("workOrder.workOrderNo")}}：{{workOrderRecord.workOrderNo}}</span>
            </div>
            <div style="padding-top: 8px;;width: 100%">
            <span
              style="padding-left:20px;font-size: 20px;font-weight: 600;color: #333333"> {{$t("workOrder.workOrderStatus")}}：
              <span
                v-if="workOrderRecord.recordState >=0 && workOrderRecord.recordState <=2"
                style="color: #333333">{{$t("workOrder.notFinished")}}</span>
              <span v-if="workOrderRecord.recordState >=3 && workOrderRecord.recordState <5">
                <span v-if="workOrderRecord.finishState === 0"
                      style="color: #333333">{{$t("workOrder.finished_")}}</span>
                <span v-if="workOrderRecord.finishState === 1"
                      style="color: #333333">{{$t("workOrder.recovery")}}</span>
                <span
                  v-if="workOrderRecord.finishState === 2"
                  style="color: #333333">{{$t("workOrder.twoAlarmCompleted")}}</span>
              </span>
              <span v-if="workOrderRecord.recordState === 5" style="color: #333333">{{$t("workOrder.cancelled")}}</span>
            </span>
            </div>
          </div>
        </div>

        <el-row v-if="false">
          <ul class="vm-separate" style="margin: 20px 0px;">
            <li v-if="workOrderRecord.recordState >= 0">
              <p class="title">{{$t("workOrder.alarmReported")}}</p>
              <p><img src="/static/images/workOrder/yi_bao_jing.png"/></p>
              <p class="date">{{workOrderRecord.alarmTime}}</p>
            </li>
            <li>
              <span v-if="workOrderRecord.acceptTime !== null && workOrderRecord.acceptTime !== ''">
                <p class="title">{{$t("workOrder.alarmed")}}</p>
                <p><img src="/static/images/workOrder/yi_jie_jing.png"/></p>
              </span>
              <span
                v-else-if="(workOrderRecord.arriveTime!==null && workOrderRecord.arriveTime!=='')
                  || (workOrderRecord.finishTime!==null && workOrderRecord.finishTime!=='')">
                <p class="title">{{$t("workOrder.unAlarmed")}}</p>
                <p><img src="/static/images/workOrder/yi_jie_jing.png"/></p>
              </span>
              <span v-else>
                <p class="title_gray">{{$t("workOrder.unAlarmed")}}</p>
                <p><img src="/static/images/workOrder/jiejing_b.png"/></p>
              </span>
              <p class="date">{{workOrderRecord.acceptTime}}</p>
            </li>
            <li>
              <span v-if="workOrderRecord.arriveTime !== null && workOrderRecord.arriveTime !== ''">
                <p class="title">{{$t("workOrder.arrived")}}</p>
                <p><img src="/static/images/workOrder/yi_dao_chang.png"/></p>
              </span>
              <span v-else-if="workOrderRecord.finishTime !== null && workOrderRecord.finishTime !== ''">
                <p class="title">{{$t("workOrder.notArrived")}}</p>
                <p><img src="/static/images/workOrder/yi_dao_chang.png"/></p>
              </span>
              <span v-else>
                <p class="title_gray">{{$t("workOrder.notArrived")}}</p>
                <p><img src="/static/images/workOrder/daochang_b.png"/></p>
              </span>
              <p class="date">{{workOrderRecord.arriveTime}}</p>
            </li>
            <li>
              <span
                v-if="workOrderRecord.finishTime !== null && workOrderRecord.finishTime !== '' && workOrderRecord.recordState >= 3">
                <span v-if="workOrderRecord.finishState === 0">
                  <p class="title">{{$t("workOrder.finished_")}}</p>
                  <p><img src="/static/images/workOrder/yi_wan_gong.png"/></p>
                </span>
                <span v-else-if="workOrderRecord.finishState === 1">
                  <p class="title">{{$t("workOrder.recovery")}}</p>
                  <p><img src="/static/images/workOrder/yi_wan_gong.png"/></p>
                </span>
                <span v-else-if="workOrderRecord.finishState === 2">
                  <p class="title">{{$t("workOrder.twoAlarmCompleted")}}</p>
                  <p><img src="/static/images/workOrder/yi_wan_gong.png"/></p>
                </span>
              </span>
              <span v-else>
                <p class="title_gray">{{$t("workOrder.notFinished")}}</p>
                <p><img src="/static/images/workOrder/wangong_b.png"/></p>
              </span>
              <p class="date">{{workOrderRecord.finishTime}}</p>
            </li>
            <li>
              <span
                v-if="workOrderRecord.confirmTime !== null && workOrderRecord.confirmTime !== '' && workOrderRecord.recordState >= 4">
                <span v-if="workOrderRecord.confirmState === 0">
                  <p class="title">{{$t("workOrder.confirmed_")}}</p>
                  <p><img src="/static/images/workOrder/yi_que_ren.png"/></p>
                </span>
                <span v-else-if="workOrderRecord.confirmState === 1">
                  <p class="title">{{$t("workOrder.notConfirmed")}}</p>
                  <p><img src="/static/images/workOrder/yi_que_ren.png"/></p>
                </span>
              </span>
              <span v-else>
                <p class="title_gray">{{$t("workOrder.autoConfirmed")}}</p>
                <p><img src="/static/images/workOrder/queren_b.png"/></p>
              </span>
              <p class="date">{{workOrderRecord.confirmTime}}</p>
            </li>
          </ul>
        </el-row>
        <div style="border: solid 1px #DCDFE6; ">
          <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;">
            <span style="margin: 10px;line-height: 30px;font-weight: 600">{{$t("workOrder.baseInfo")}}</span>
          </div>
          <div style="border-bottom: solid 1px #DCDFE6;margin: 0px 20px 0px 20px;">
            <div style="margin: 10px;">
              <span
                style="line-height: 25px;font-weight: 600;font-size: 15px;color: #2B80DA;">{{$t("workOrder.workOrder")}}</span>
              <div class="vm-separate">
                <span>
                  {{$t("workOrder.faultTime")}}：{{workOrderRecord.faultTime}}
                </span>
                <span>
                  {{$t("workOrder.callTime")}}：{{workOrderRecord.callTime}}
                </span>
              </div>
              <div class="vm-separate">
                <span>
                  {{$t("workOrder.alarmReportedBy")}}：
                  <span v-if="workOrderRecord.repairMethod === 1">
                    <el-tag type="danger">{{$t("workOrder.selfRepair")}}</el-tag>
                  </span>
                  <span v-else>
                    <span
                      v-if="workOrderRecord.repairPeople === null || workOrderRecord.repairPeople.length === 0">[未记录]</span>
                    <span>{{workOrderRecord.repairPeople}}</span>
                  </span>
                </span>
                <span>{{$t("workOrder.alarmPeopleTel")}}：{{workOrderRecord.repairPhone}}</span>
              </div>
              <div class="vm-separate">
                <span>
                  {{$t("workOrder.alarmType")}}：
                  <span v-if="workOrderRecord.alarmType === 0" style="cursor:pointer"><img
                    height="15px"
                    src="/static/images/elevator/tingti.png"
                    :title="$t('workOrder.alarmType_0')"/>&nbsp;{{$t("workOrder.alarmType_0")}}</span>
                  <span v-if="workOrderRecord.alarmType === 1" style="cursor:pointer"><img
                    height="15px"
                    src="/static/images/elevator/guanren.png"
                    :title="$t('workOrder.alarmType_1')"/>&nbsp;{{$t("workOrder.alarmType_1")}}</span>
                  <span v-if="workOrderRecord.alarmType === 2" style="cursor:pointer"><img
                    height="15px"
                    src="/static/images/elevator/serious.png"
                    :title="$t('workOrder.alarmType_2')"/>&nbsp;{{$t("workOrder.alarmType_2")}}</span>
                  <span v-if="workOrderRecord.alarmType === 3" style="cursor:pointer"><img
                    height="15px"
                    src="/static/images/elevator/jingling.png"
                    :title="$t('workOrder.alarmType_3')"/>&nbsp;{{$t("workOrder.alarmType_3")}}</span>
                  <span
                    v-if="workOrderRecord.alarmType === 4">{{$t("workOrder.deviceReasonTrapped")}}</span>
                  <span
                    v-if="workOrderRecord.alarmType === 5">{{$t("workOrder.deviceReasonSafe")}}</span>
                  <span
                    v-if="workOrderRecord.alarmType === 6">{{$t("workOrder.elevatorDamageOrFault")}}</span>
                  <span v-if="workOrderRecord.alarmType === 7">{{$t("workOrder.minorIssues")}}</span>
                  <span
                    v-if="workOrderRecord.alarmType === 8">{{$t("workOrder.userReasonNoTrapped")}}</span>
                  <span
                    v-if="workOrderRecord.alarmType === 9">{{$t("workOrder.userReasonTrapped")}}</span>
                </span>
                <span>
                  {{$t("workOrder.inspection")}}：
                  <span v-if="workOrderRecord.isYearFault === 0">{{$t("common.no")}}</span>
                  <span v-if="workOrderRecord.isYearFault === 1">{{$t("common.yes")}}</span>
                </span>
              </div>
              <div class="vm-separate">
                <span>{{$t("workOrder.repairPeople")}}：{{workOrderRecord.alarmPeopleName}}</span>
                <span>{{$t("workOrder.acceptor")}}：{{workOrderRecord.acceptorName}}</span>
              </div>
              <div class="vm-separate">
                <span>{{$t("workOrder.revocationReason")}}：{{workOrderRecord.revokeReason}}</span>
                <span v-if="workOrderRecord.recordState === 4">{{$t("workOrder.confirmPeople")}}：
                  <span v-if="workOrderRecord.confirmPeopleId === 0">{{$t("workOrder.system")}}</span>
                  <span v-else>{{workOrderRecord.confirmPeopleName}}</span>
                </span>
              </div>
              <div class="vm-separate">
                <span>{{$t("workOrder.faultReason")}}：{{workOrderRecord.faultReasonDesc}}</span>
                <span>{{$t("workOrder.handleMethod")}}：{{workOrderRecord.handleMethod}}</span>
              </div>
            </div>
          </div>
          <div style="border-bottom: solid 1px #DCDFE6;margin: 0px 20px 0px 20px;">
            <div style="margin: 10px;">
              <span
                style="line-height: 25px;font-weight: 600;font-size: 15px;color: #2B80DA;">{{$t("workOrder.propertyInfo")}}</span>
              <div class="vm-separate">
                <span>{{$t("workOrder.propertyInfo")}}：{{workOrderRecord.propertyComName}}</span>
              </div>
              <div class="vm-separate">
                <span>{{$t("workOrder.contactPerson")}}：{{workOrderRecord.propertyComPerson}}</span>
                <span>
                  {{$t("workOrder.contactTel")}}：{{workOrderRecord.propertyComPhone}}
                </span>
              </div>
            </div>
          </div>
          <div style="border-bottom: solid 1px #DCDFE6;margin: 0px 20px 0px 20px;">
            <div style="margin: 10px;">
              <span
                style="line-height: 25px;font-weight: 600;font-size: 15px;color: #2B80DA;">{{$t("elevator.maintenanced")}}</span>
              <div class="vm-separate">
                <span>{{$t("elevator.maintenanced")}}：{{workOrderRecord.maintComName}}</span>
              </div>
              <div class="vm-separate">
                <span>{{$t("workOrder.servicePersonnel")}}：
                  <span
                    v-for="(personnel,index) in workOrderRecord.workOrderPersonnels"
                    :key="index"> {{personnel.userName}}
                    ({{personnel.userMobile}})&nbsp; &nbsp;</span>
                </span>
              </div>
            </div>
          </div>
          <div style="margin: 0px 20px 0px 20px;">
            <div style="margin: 10px;">
              <span
                style="line-height: 25px;font-weight: 600;font-size: 15px;color: #2B80DA;">{{$t("workOrder.elevatorInfo")}}</span>
              <div class="vm-separate">
                <span>{{$t("elevator.no")}}：{{workOrderRecord.elevatorNo}}&nbsp;</span>
                <span>{{$t("elevator.name")}}：{{workOrderRecord.elevatorName}}</span>
              </div>
              <div class="vm-separate">
                <span>{{$t("elevator.maintWorker")}}：{{workOrderRecord.maintName}}<span
                  v-if="workOrderRecord.maintEmpMobile !== null">({{workOrderRecord.maintEmpMobile}})</span></span>
                <span>{{$t("elevator.elevatorType")}}：{{workOrderRecord.elevatorProductName}}</span>
              </div>
              <div class="vm-separate">
                <span>{{$t("elevator.model")}}：{{workOrderRecord.product}}</span>
                <span>{{$t("elevator.address")}}：{{workOrderRecord.address}}</span>
              </div>
              <div class="vm-separate">
                <span>{{$t("elevator.projectName")}}：{{workOrderRecord.projectName}}</span>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div style="border: solid 1px #DCDFE6;margin-top: 20px">
          <div
            style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;display: flex;justify-content: space-between">
            <span
              style="margin: 5px 10px;font-weight: 600;color: #2B80DA;cursor:pointer;"
              @click="display1 = changeDisplay(display1)">{{$t("elevator.faultRecord")}}<i
              v-if="display1 === 'block'"
              class="el-icon-arrow-down"></i><i
              v-else
              class="el-icon-arrow-up"></i></span>
            <!--          <span-->
            <!--            style="margin: 5px 10px;font-weight: 600;color: #2B80DA;cursor:pointer;"-->
            <!--            @click="gotoImageList">{{$t("workOrder.screenshot","截图")}}</span>-->
          </div>
          <div :style="{display: display1}">
            <el-table
              :data="workOrderRecord.faultRecords"
              border
              stripe
              default-expand-all
              style="width: 100%">
              <el-table-column type="expand">
                <template v-slot="scope">
                  <table class="childTable">
                    <tr>
                      <th style="width: 5%">{{$t("common.index")}}</th>
                      <th style="width: 7%">{{$t("faultTemplate.subCode")}}</th>
                      <th style="width: 30%">{{$t("faultTemplate.reason")}}</th>
                      <th>{{$t("faultTemplate.solution")}}</th>
                    </tr>
                    <tr v-for="(solution, index) in scope.row.solutionSettings" :key="index">
                      <td style="text-align: center">{{index + 1}}</td>
                      <td></td>
                      <td>{{solution.reason}}</td>
                      <td>{{solution.solution}}</td>
                    </tr>
                  </table>
                </template>
              </el-table-column>
              <el-table-column prop="faultCode" :label="$t('workOrder.faultNo')" align="center"></el-table-column>
              <el-table-column v-if="false" prop="faultCode" :label="$t('workOrder.hex')" align="center">
                <template slot-scope="scope">
                  {{Number(scope.row.faultCode).toString(16)}}
                </template>
              </el-table-column>
              <el-table-column prop="faultDesc" :label="$t('workOrder.faultType')" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.faultDesc === '000000' ">{{$t("workOrder.unKnowFault")}}</span>
                  <span v-else>{{scope.row.faultDesc}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="faultTime" :label="$t('workOrder.faultTime')" align="center"></el-table-column>
              <el-table-column
                v-if="false"
                prop="sendTime"
                :label="$t('workOrder.mainBoardTime')"
                align="center"></el-table-column>
              <el-table-column v-if="false" prop="faultFloor" :label="$t('workOrder.faultStorey')"></el-table-column>
              <el-table-column
                prop="faultFloorCode"
                :label="$t('workOrder.faultFloorCode')"
                width="110px"
                align="center"></el-table-column>
              <el-table-column
                prop="displayFaultFloor"
                :label="$t('workOrder.displayFaultFloor')"
                width="110px"
                align="center"></el-table-column>
            </el-table>
          </div>
        </div>
        <div style="border: solid 1px #DCDFE6;margin-top: 20px">
          <div style="background-color: #E4F0F4;border-bottom: solid 1px #DCDFE6;">
            <span
              style="margin: 10px;line-height: 30px;font-weight: 600;color: #2B80DA;cursor:pointer;"
              @click="display2 = changeDisplay(display2)">{{$t("workOrder.workorderInfoTrack")}}<i
              v-if="display2 === 'block'"
              class="el-icon-arrow-down"></i><i v-else class="el-icon-arrow-up"></i></span>
          </div>
          <div :style="{display: display2}">
            <div
              v-for="(workOrderStateNote,index) in workOrderRecord.workOrderStateNotes"
              :key="index"
              style="">
              <div style="margin: 10px;font-size: 14px;">
                <span v-if="workOrderStateNote.userId === 0" style="margin: 20px">
                  <img src="/static/images/elevator/xitong.png" :title="$t('workOrder.system')"/>
                </span>
                <span v-else style="margin: 20px;">
                  {{workOrderStateNote.userName}}
                </span>
                <span style="margin: 20px">{{workOrderStateNote.datetime}}</span>
                <span style="margin: 20px;width: 300px">{{workOrderStateNote.note}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!showContent" class="content1">
        {{showMsg}}
      </div>
    </div>
    <div class="footer">
    </div>
  </div>
</template>

<script>
  const moduleName = "workOrders";
  import {Base64} from "js-base64";

  export default {
    components: {},
    data() {
      return {
        copyright: window.config.copyright,
        showContent: false,
        showMsg: "",
        stepActive: 1,
        display1: "block",
        display2: "block",
        display3: "block",
        activeTab: "first",
        tableData1: [],
        tableData2: [],
        total1: 0,
        total2: 0,
        currentPage1: 1,
        currentPage2: 1,
        workOrderRecord: {
          id: 0,
          faultTime: "",
          projectName: "",
          callTime: "",
          repairPhone: "",
          workOrderNo: "",
          repairPeople: "",
          handleMethod: "",
          faultReasonDesc: "",
          alarmPeopleName: "",
          alarmPeopleId: 0,
          alarmType: 0,
          isYearFault: 0,
          acceptorName: "",
          confirmPeopleId: "",
          confirmPeopleName: "",
          acceptTime: "",
          alarmTime: "",
          arriveTime: "",
          finishTime: "",
          confirmTime: "",
          cancelTime: "",
          revokeReason: "",
          confirmState: -1,
          recordState: -1,
          finishState: -1,
          clientId: 0,
          useUnitName: "",
          repairMethod: "",
          elevatorId: 0,
          elevatorNo: "",
          elevatorName: "",
          dtuCode: "",
          address: "",
          propertyComName: "",
          propertyComPerson: "",
          propertyComTel: "",
          propertyComPhone: "",
          maintComName: "",
          maintName: "",
          maintEmpMobile: "",
          elevatorProductName: "",
          product: "",
          contactPerson: "",
          contactTel: "",
          alarmSolution: "",
          note: "",
          alarmReason: "",
          workOrderPersonnels: [],
          workOrderStateNotes: [],
          faultRecords: [],
        },
      };
    },
    mounted() {
      this.workOrderRecord.id = this.$route.params.workOrderId;
      let ex = this.$route.query.ex;
      this.getData();
    },
    methods: {
      getData() {
        this.$api.getById(moduleName + "/detailH5", this.workOrderRecord.id).then(res => {

          if (res.data.status == 1) {
            this.workOrderRecord = Object.assign(this.workOrderRecord, res.data.data);
            this.showContent = true;
            this.showMsg = "";
            if (!this.$i18n.isCn) {
              if (this.workOrderRecord.elevatorProductName === "曳引驱动乘客电梯") {
                this.workOrderRecord.elevatorProductName = "Traction Drive Passenger Elevator";
              }
              if (this.workOrderRecord.elevatorProductName === "曳引驱动载货电梯") {
                this.workOrderRecord.elevatorProductName = "Traction Drive Freight Elevator";
              }
              if (this.workOrderRecord.elevatorProductName === "强制驱动载货电梯") {
                this.workOrderRecord.elevatorProductName = "Forced Drive Freight Elevator";
              }
              if (this.workOrderRecord.elevatorProductName === "液压乘客电梯") {
                this.workOrderRecord.elevatorProductName = "Hydraulic Passenger Elevator";
              }
              if (this.workOrderRecord.elevatorProductName === "液压载货电梯") {
                this.workOrderRecord.elevatorProductName = "Hydraulic Freight Elevator";
              }
              if (this.workOrderRecord.elevatorProductName === "自动扶梯") {
                this.workOrderRecord.elevatorProductName = "Escalator";
              }
              if (this.workOrderRecord.elevatorProductName === "自动人行道") {
                this.workOrderRecord.elevatorProductName = "Moving Walk";
              }
              if (this.workOrderRecord.elevatorProductName === "防爆电梯") {
                this.workOrderRecord.elevatorProductName = "Explosion-proof Elevator";
              }
              if (this.workOrderRecord.elevatorProductName === "消防员电梯") {
                this.workOrderRecord.elevatorProductName = "Firefighter Elevator";
              }
              if (this.workOrderRecord.elevatorProductName === "杂物电梯") {
                this.workOrderRecord.elevatorProductName = "Debris Elevator";
              }
              for (let i = 0; i < this.workOrderRecord.workOrderStateNotes.length; i++) {
                if (this.workOrderRecord.workOrderStateNotes[i].note === "有人") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Has People";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "无人") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "No People";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "急修报警") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair The Alarm";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "急修接警") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair Called";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "急修到场") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair To Be Present";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "急修完工") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repairing Completion";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "急修确认") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair Confirmation";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "急修撤销") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair To Cancel";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "急修暂停") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair Suspended";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "急修恢复") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Urgent Repair Back";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "自动转检修") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Automatic To Overhaul";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "检修转自动") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Overhaul To Automatic";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "故障转检修") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Fault To Overhaul";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "检修转故障") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Overhaul To Fault";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "报警发送A0") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Alarm Send A0";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "报警收到A0门锁打开") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Alarm Received A0 Door Lock Open";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "查询电梯状态") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Query Elevator Status";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "报警收到A0门锁闭合") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Alarm received A0 door lock closed";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "急修二次报警完工") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Emergency Repair Secondary Alarm Completed";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "自动确认") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Automatic Confirmation";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "自动撤销转维保记录") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Automatically Cancel The Transfer Maintenance Record";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "警铃报警") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Alarm Bell";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "严重故障") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Critical Failure";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "自动") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Auto";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "自动故障") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Auto Failure";
                }
                if (this.workOrderRecord.workOrderStateNotes[i].note === "检修自动") {
                  this.workOrderRecord.workOrderStateNotes[i].note = "Overhaul Automatically";
                }
              }
            }
            this.initStepActive();
            this.getList1(1);
            this.getList2(1);
          } else {
            this.showContent = false;
            this.showMsg = "页面访问受限";
          }
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "," + error.response.data.message);
          }
        });
      },
      getList1(pageIndex) {
        this.currentPage1 = pageIndex;
        let params;
        if (this.workOrderRecord.recordState >= 0 && this.workOrderRecord.recordState < 3) {
          params = {
            pageIndex,
            parentNo: 0,
            no: this.workOrderRecord.dtuCode,
            startTime: this.workOrderRecord.alarmTime,
            endTime: "",
          };
        } else if (this.workOrderRecord.recordState >= 3 && this.workOrderRecord.recordState <= 4) {
          params = {
            pageIndex,
            parentNo: 0,
            no: this.workOrderRecord.dtuCode,
            startTime: this.workOrderRecord.alarmTime,
            endTime: this.workOrderRecord.finishTime,
          };
        } else if (this.workOrderRecord.recordState === 5) {
          params = {
            pageIndex,
            parentNo: 0,
            no: this.workOrderRecord.dtuCode,
            startTime: this.workOrderRecord.alarmTime,
            endTime: this.workOrderRecord.cancelTime,
          };
        }
      },
      getList2(pageIndex) {
        this.currentPage2 = pageIndex;
        let params;
        if (this.workOrderRecord.recordState >= 0 && this.workOrderRecord.recordState < 3) {
          params = {
            pageIndex,
            parentNo: 0,
            no: this.workOrderRecord.dtuCode,
            startTime: this.workOrderRecord.alarmTime,
            endTime: "",
          };
        } else if (this.workOrderRecord.recordState >= 3 && this.workOrderRecord.recordState <= 4) {
          params = {
            pageIndex,
            parentNo: 0,
            no: this.workOrderRecord.dtuCode,
            startTime: this.workOrderRecord.alarmTime,
            endTime: this.workOrderRecord.finishTime,
          };
        } else if (this.workOrderRecord.recordState === 5) {
          params = {
            pageIndex,
            parentNo: 0,
            no: this.workOrderRecord.dtuCode,
            startTime: this.workOrderRecord.alarmTime,
            endTime: this.workOrderRecord.cancelTime,
          };
        }
      },
      initStepActive() {
        if (this.workOrderRecord.recordState === 1) {
          this.stepActive = 2;
        }
        if (this.workOrderRecord.recordState === 2) {
          this.stepActive = 3;
        }
        if (this.workOrderRecord.recordState >= 3) {
          this.stepActive = 4;
        }
        if (this.workOrderRecord.recordState >= 4) {
          this.stepActive = 5;
        }
        if (this.workOrderRecord.recordState === 5) {
          this.stepActive = 6;
        }
      },
      changePage1(pageIndex) {
        this.getList1(pageIndex);
      },
      changePage2(pageIndex) {
        this.getList2(pageIndex);
      },
      changeDisplay(e) {
        if (e === "block") {
          e = "none";
        } else {
          e = "block";
        }
        return e;
      },
    },
  };
</script>
<style lang="scss" scoped>

.body22 {
  background-color: #f2f2f2;
  height: 100%;
  overflow-y: scroll;

  .back {
    width: 100%;

    .logo {
      width: 100%;
      margin: 0 auto;
    }

    .content {
      width: 100%;
      background-color: white;
      margin: 0 auto;
      border: solid 1px #DCDFE6;
      padding-bottom: 100px;
    }

    .content1 {
      width: 100%;
      background-color: white;
      margin: 0 auto;
      border: solid 1px #DCDFE6;
      padding-bottom: 100px;
      text-align: center;
    }
  }

  .footer {
    color: #FFF;
    background-color: #404040;
    position: fixed;
    text-align: center;
    width: 100%;
    left: 0;
    bottom: 0px;
    z-index: 100;
  }
}


span {
  color: #606266;
}

.vm-separate {
  font-size: 12px;
  margin-bottom: 6px;
  margin-left: 6px;
  margin-right: 6px;
}

ul {
  white-space: nowrap;
  width: 92%;
}

li {
  list-style-type: none;
  display: inline;
}

p {
  margin: 0;
}

.title {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #5a9d11;
  margin-left: -10px;
}

.title_gray {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  color: #999999;
  margin-left: -10px;
}

.date {
  font-size: 12px;
  color: #666666;
  text-align: center;
  margin-top: 5px;
}

.childTable {
  width: 100%;
  border-collapse: collapse;

  td {
    border: 1px solid #D8DCE5;
    padding: 5px 10px;
    background-color: #FAFAFA;
  }

  th {
    font-weight: 500;
    text-align: center;
    border: 1px solid #D8DCE5;
    padding: 8px 10px;
    background-color: #EEF2F7;
  }
}
</style>
